<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <div class="container container-lead bg-white border-radius-4">
        <div class="card-header p-0">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              w-100
              spacing-b-15
            "
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="form-select-custom form-select-align">
                <div class="multiselect-dropdown-custom multiselect-simple">
                  <el-date-picker
                    unlink-panels
                    format="DD/MM/YYYY"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    range-separator="-"
                    :clearable="false"
                    size="large"
                    v-model="dates"
                    style="width: 310px"
                    @change="changeCalendar"
                  />
                </div>
              </div>

              <div class="form-select-custom form-select-align">
                <div class="multiselect-dropdown-custom multiselect-simple">
                  <el-select
                    filterable
                    v-model="year"
                    placeholder="Select Year"
                    size="large"
                    style="width: 100%"
                    @change="handleYears"
                  >
                    <el-option value="" label="Select Year" />
                    <el-option
                      v-for="item in common.getYearList()"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </div>

              <div class="form-select-custom form-select-align">
                <div class="multiselect-dropdown-custom multiselect-simple">
                  <el-select
                    filterable
                    v-model="filter.lead_source"
                    placeholder="Select Sales Agent"
                    size="large"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in leadSources"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>

              <div class="form-select-custom form-select-align">
                <div class="multiselect-dropdown-custom multiselect-simple">
                  <el-select
                    filterable
                    v-model="filter.lead_status"
                    placeholder="Lead Status"
                    size="large"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in leadStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div class="container spacing-b-15">
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                lead-source-row
              "
            >
              <div class="d-flex align-items-center">
                <div class="form-select-custom form-select-align">
                  <div class="multiselect-dropdown-custom multiselect-simple">
                    <el-select
                      filterable
                      v-model="filter.utm_source"
                      placeholder="All UTM Sources"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in utmSource"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>

                <div class="form-select-custom form-select-align">
                  <div class="multiselect-dropdown-custom multiselect-simple">
                    <el-select
                      filterable
                      v-model="filter.utm_medium"
                      placeholder="All UTM Mediums"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in utmMedium"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>

                <div class="form-select-custom form-select-align">
                  <div class="multiselect-dropdown-custom multiselect-simple">
                    <el-select
                      filterable
                      v-model="filter.utm_campaign"
                      placeholder="All UTM Campaigns"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in utmCampaign"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>

                <div class="form-select-custom form-select-align">
                  <div class="multiselect-dropdown-custom multiselect-simple">
                    <el-select
                      filterable
                      v-model="filter.utm_content"
                      placeholder="All UTM Sources"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in utmContent"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container spacing-b-15">
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                lead-source-row
              "
            >
            <div class="d-flex align-items-center"></div>
              <div
                class="
                  d-flex
                  justify-content-end
                  search-block
                  lead-filter-buttons
                "
              >
                <div
                  class="export-btn d-flex align-items-center"
                  v-if="[1, 11].includes(store.getters.currentRole)"
                >
                  <span class="ms-10 mt-10" v-if="exportUrl"
                    ><a
                      ref="download"
                      :href="exportUrl"
                      target="_blank"
                      >Click to download</a
                    ></span
                  >

                  <button
                    :data-kt-indicator="disableExport ? 'on' : null"
                    @click="exportReport"
                    class="btn btn-info btn-danger"
                    type="button"
                  >
                    <span v-if="!disableExport" class="indicator-label">
                      <img src="@/assets/images/export-xls.svg" alt="Export" />
                    </span>
                    <span v-if="disableExport" class="indicator-progress">
                      Please wait...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                </div>

                <div class="search-buttons-block d-flex align-items-center">
                  <div class="bd-highlight">
                    <button
                      type="button"
                      class="btn btn-light-info"
                      @click="resetAndRefreshFilter"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body card-custom-spacing-tblf-10 bg-white">
        <div
          class="d-flex search-block lead-filter-buttons mb-5"
          :class="{ 'justify-content-end': !textMessage }"
          :style="textMessage ? 'justify-content: space-between' : ''"
        >
          <a
            class="
              leads
              nav-link
              alert
              bg-light-danger
              align-items-center
              leads-btn
            "
          >
            {{ theTable.total }}
            Record Count
          </a>
        </div>
        <div class="pt-0 p-0" v-if="leadBySource && leadBySource.length > 0">
          <div id="displayResponseData">
            <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
            <el-table :data="leadBySource">
              <el-table-column
                v-for="list in authorized_headers"
                :key="list.label"
                :fixed="list.isFixed"
                :width="list.width"
              >
                <template #header>
                  <span v-html="list.label" />
                </template>
                <template #default="scope">
                  <span v-if="list.isIndex">
                    {{ scope.$index + (srs + 1) }}
                  </span>
                  <span v-else-if="list.isFormat">
                    {{ common.setDateTimeOnly(scope.row[list.value]) }}
                  </span>
                  <span v-else-if="list.isCount">
                    <span
                      v-for="(n, index) in scope.row[list.value]"
                      :key="index"
                    >
                      {{ index + "(" + n + "), " }}
                    </span>
                  </span>
                  <span
                    v-else
                    :class="list.cn"
                    :style="
                      list.isMixin
                        ? common.leadStatus(scope.row.lead_status)
                        : ''
                    "
                    v-html="scope.row[list.value]"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <span>Loading Information Please Wait...</span>
          </div>
          <div v-else class="container-fluid p-5">
            <div class="row">
              <div class="col-12">
                <el-empty description="No Records Found" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer bg-white">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-4">
              <div
                class="
                  col-sm-12 col-md-5
                  d-flex
                  align-items-center
                  justify-content-center justify-content-md-start
                "
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <el-select
                      v-model="filter.per_page"
                      @change="currentPage = 1"
                      size="large"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="page in per_pages"
                        :key="page"
                        :value="page"
                      >
                        {{ page }}
                      </el-option>
                    </el-select>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-4 text-center">
              <span class="text-muted d-block mt-2">
                Showing
                <span v-if="theTable.total > 0">
                  {{ theTable.from }} to {{ theTable.to }} of
                </span>
                <span v-else> {{ theTable.total }} of </span>
                {{ theTable.total }}
              </span>
            </div>
            <div class="col-4">
              <div style="float: right">
                <el-pagination
                  v-if="theTable"
                  v-model:currentPage="currentPage"
                  :page-size="parseInt(theTable.per_page)"
                  :small="large"
                  layout="prev, pager, next"
                  :total="theTable.total"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import Report from "@/core/services/Callable/Report";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { common } from "@/store/stateless";
import {
  salesOptions,
  leadsOptions,
  dashboard_details_table,
} from "@/core/utils/reports";
import { per_pages } from "@/core/utils/common";
import Etcs from "@/core/services/Callable/Etcs";
import { store } from "@/store";
import { useRouter } from "vue-router";
import { date } from "yup/lib/locale";

const router = useRouter();
const filter = ref({
  agent: "all",
  start_date: moment().startOf('month').format("YYYY-MM-DD"),
  lead_source: "all",
  lead_status: "all",
  utm_source: "all",
  utm_medium: "all",
  utm_campaign: "all",
  utm_content: "all",
  page: 1,
  per_page: 50,
  sales: "all",
  end_date: moment().endOf('month').format("YYYY-MM-DD"),
});

const dates = ref([]);
const year = ref("");
const download = ref();
const currentPage = ref(1);
const searchy = ref([]);
const disableExport = ref(false);
const loading = ref(false);
const exportUrl = ref("");

const authorized_headers = computed(() => {
  if(!store.state.authorized_headers){
    return dashboard_details_table
    .filter(y => y.value != 'email')
    .filter(y => y.value != 'mobile')
  }
  return dashboard_details_table
})

onMounted(async () => {
  setCurrentPageBreadcrumbs("Lead By Source", [
    { label: "Reports", value: "" },
  ]);

  const fetchRecord = await getQuery();
  dates.value = [filter.value.start_date, filter.value.end_date];

  if (fetchRecord) await searchData(filter.value);

  await Report.getReportLeadBySourceFilter();
});

async function getQuery() {
  if (
    router.currentRoute.value.query &&
    router.currentRoute.value.query.start_date &&
    router.currentRoute.value.query.end_date &&
    (router.currentRoute.value.query.sales ||
      router.currentRoute.value.query.leads)
  ) {
    filter.value.start_date =
      router.currentRoute.value.query.start_date.toString();
    filter.value.end_date = router.currentRoute.value.query.end_date.toString();
    if (router.currentRoute.value.query.sales) {
      filter.value.leads = "all";
      filter.value.sales = router.currentRoute.value.query.sales.toString();
    }

    if (router.currentRoute.value.query.leads) {
      filter.value.leads = router.currentRoute.value.query.leads.toString();
      filter.value.sales = "all";
    }

    return false;
  }

  return true;
}

function changeCalendar() {
  filter.value.start_date = dates.value[0];
  filter.value.end_date = dates.value[1];
  currentPage.value = 1;
  year.value = "";
}

watch(
  [() => filter.value, () => currentPage.value],
  async ([newValue, newCurrent], [oldValue]) => {
    newValue.page = newCurrent;
    exportUrl.value = '';

    searchData(newValue);
  },
  {
    deep: true,
  }
);

const theTable = computed(() => {
  return store.state.report.lead_by_source;
});

const leadSources = computed(() => {
  let data = store.state.report.filters.lead_sources;
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

   data.unshift({
    value: "all",
    label: "All Lead Sources",
  });

  return data;
});



const utmSource = computed(() => {
  let data = store.state.report.filters.utm_sources;
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

  data.unshift({
    value: "all",
    label: "All UTM Sources",
  });

  return data;
});

const utmCampaign = computed(() => {
  let data = store.state.report.filters.utm_campaign;
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

 data.unshift({
    value: "all",
    label: "All UTM Campaigns",
  });

  return data;
});

const utmMedium = computed(() => {
  let data = store.state.report.filters.utm_medium;
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

  data.unshift({
    value: "all",
    label: "All UTM Mediums",
  });

  return data;
});

const utmContent = computed(() => {
  let data = store.state.report.filters.utm_content;
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

   data.unshift({
    value: "all",
    label: "All UTM Contents",
  });

  return data;
});

const leadStatus = computed(() => {
  let data = store.state.report.filters.lead_statuses;
  // console.log('sdfssfsfdsf',data)
  const exist = data.filter((x) => x.value == "all");
  if (exist.length > 0) return data;

  data.unshift({
    value: "all",
    label: "Lead Status",
  });

  console.log('leadsstatus',data)
  return data;
});

const leadBySource = computed(() => {
  return store.getters.getLeadBySourceReport;
});

const srs = computed(() => {
  return (filter.value.page - 1) * filter.value.per_page;
});

function handleYears() {
  currentPage.value = 1;
  const newDate = moment().year(year.value);
  const firstDate = new Date(newDate.year(), 0, 1);
  const lastDate = new Date(newDate.year(), 11, 31);
  filter.value.start_date = moment(firstDate).format("YYYY-MM-DD");
  filter.value.end_date = moment(lastDate).format("YYYY-MM-DD");

  dates.value = [filter.value.start_date, filter.value.end_date];
}

function filterSales() {
  currentPage.value = 1;
  filter.value.leads = "all";
}

function filterLeads() {
  currentPage.value = 1;
  filter.value.sales = "all";
}

function resetAndRefreshFilter() {
  filter.value = {
  agent: "all",
  start_date: moment().startOf('month').format("YYYY-MM-DD"),
  lead_source: "all",
  lead_status: "all",
  utm_source: "all",
  utm_medium: "all",
  utm_campaign: "all",
  utm_content: "all",
  page: 1,
  per_page: 50,
  sales: "all",
  end_date: moment().endOf('month').format("YYYY-MM-DD"),
}

  dates.value = [filter.value.start_date, filter.value.end_date];
  year.value = "";
}

async function exportReport() {
  const filty = {
    ...filter.value,
    export: true,
  };
  disableExport.value = true;
  await searchData(filty);
}

async function searchData(filty) {
  loading.value = true;
  const data = await Report.getLeadBySourceReport(filty);
  disableExport.value = false;
  loading.value = false;
  window.scrollTo(0, 0);
  if (!filty.export) searchy.value = data;
  else {
    exportUrl.value = data

    // exportUrl.value = data.data
    // setTimeout(() => {
    //     download.value.click()
    // }, 500);
    // window.open(data.data);
  }
}
</script>
